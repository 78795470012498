<template>
  <div class="header" :class="{ active: ativo }">
    <div class="logo" ><router-link :to="{ name: 'home-PT' }"><img src="@/assets/images/logo2.png" alt=""></router-link></div>
    <div class="nav">
      <ul>
        <li ><router-link exact active-class="ativo" :to="{ name: 'home-PT' }">{{$t('header.li1')}}</router-link></li>
        <li ><router-link active-class="ativo" :to="{ name: 'about-PT' }">{{$t('header.li2')}}</router-link></li>
        <li class="submenu"><router-link active-class="ativo" :to="{ name: 'refugees-menu-PT' }">{{$t('header.li3')}}</router-link></li>
        <li ><a href="/#contact" @click="ativo = false">{{$t('header.li4')}}</a></li>
        <li >
          <select class="language-switch" v-model="lang" @change="handleChange($event)">
            <option value="pt">Português</option>
            <option value="en">English</option>
            <option value="es">Español</option>
            <option value="fr">Français</option>
            <option value="ar">عربى</option>
          </select>
          <img id="flag" v-if="lang == 'pt'" src="https://img.icons8.com/color/35/000000/brazil.png"/>
          <img id="flag" v-if="lang == 'en'" src="https://img.icons8.com/color/35/000000/usa.png"/>
          <img id="flag" v-if="lang == 'fr'" src="https://img.icons8.com/color/35/000000/france.png"/>
          <img id="flag" v-if="lang == 'es'" src="https://img.icons8.com/color/35/000000/spain-2.png"/>
          <img id="flag" v-if="lang == 'ar'" src="https://img.icons8.com/color/35/000000/syria.png"/>
        </li>
      </ul>
      <div class="toggle" @click="ativo = !ativo">
        <img src="@/assets/images/menu.png" alt="">
      </div>
    </div>
  </div>
</template>

<script>
/* window.addEventListener('scroll', () =>{
 let header = document.querySelector('.header')
 header.classList.toggle('sticky', window.scrollY > 0)
}) */

export default {
  data() {
    const lang = localStorage.getItem("lang");
    return {
      lang: lang,
      ativo: false
    };
  },
  methods: {
    toggle() {
      let header = document.querySelector(".header");
      header.classList.toggle("active");
    },
    handleChange(event) {
      localStorage.setItem("lang", event.target.value);
      window.location.reload();
    },
  },
};
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}
.destaque{
  color: #ff9b13;
}
.header {
  position: sticky;
  top: 0;
  width: 100%;
  padding: 0 120px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  z-index: 1000;
  transition: 0.5s;
}
.header .logo {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.header .logo img{
  width: 70px; 
  margin: 5px 5px 5px 0;
}
.nav > ul {
  position: relative;
  display: flex;
  align-items: center;
}
.nav > ul li {
  list-style: none;
}
.header .nav a.ativo {
  background: #ff9b13;
  color: #ffffff;
}
/* .nav ul li a.router-link-exact-active{
  background: #ff9b13;
  color: #fff;
} */
.nav > ul li a {
  text-decoration: none;
  margin-left: 20px;
  padding: 5px 12px;
  font-size: 18px;
  color: #414141;
  border-radius: 30px;
  font-weight: 600;
  text-align: center;
  transition: 0.4s;
}
.language-switch {
  margin-left: 30px;
  margin-right: 10px;
  cursor: pointer;
  outline: none;
  border: none;
  background: #fff;
  text-decoration: none;
  color: #1f4e79 ;
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  transform: translateY(-7px);
}
#flag{
  transform: translateY(4px);
}
.nav > ul li a:hover {
  background: #ff9b13;
  color: #fff;
  transition: 0.4s;
}
.toggle img{
  display: none;
}
@media(max-width: 1320px){
  .header{
    padding: 0 50px;
  }
}
@media(max-width: 1170px){
  .header{
    padding: 0 20px;
  }
}
@media (max-width: 1120px){
  .header .nav ul li a{
    font-size: 15px;
  }
  .language-switch{
    font-size: 15px;
  }
}
@media (max-width: 940px) {
  .toggle {
    display: block;
    cursor: pointer;
  }
  .toggle img {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
  }
  .header {
    background: #fff;
    padding: 0 20px;
  }
  .header a {
    font-weight: bold;
  }
  .header .nav ul {
    position: absolute;
    top: 80px;
    left: calc(100% - 400px);
    width: 400px;
    height: 100vh;
    padding: 0;
    text-align: right;
    display: block;
    overflow: auto;
    background: rgb(255, 255, 255, 0.8);
    visibility: hidden;
    opacity: 0;
    z-index: 10000;
    transition: all ease 0.2s;
  }
  .header .nav ul li select{
    background: transparent;
  }
  .active .nav ul {
    visibility: visible;
    opacity: 1;
    display: block;
    text-align: right;
    padding: 10px 0 0 0;
    transition: 0.5s;
    z-index: 10000;
  }
  .active .nav ul li {
    padding: 10px;
    font-size: 22px;
    transition: 0.4s;
  }
  .active .nav ul li a {
    color: #000;
    font-size: 22px;
    text-align: center;
    transition: 0.4s;
  }
  .active .nav ul li a.router-link-exact-active{
    background: #ff9b13;
    color: #fff;
  }
  .active .nav ul li a:hover{
    color: #000;
    background: transparent;
  }
  .language-switch{
    font-size: 20px;
  }
  #flag{
    margin-right: 10px;
  }
}
@media (max-width: 910px){
.header .logo img{
  width: 60px;
}
.header .nav ul{
  width: 100%;
  top: 60px;
  left: 0;
  background: #fff;
}
.header .nav ul li{
  text-align: right;
}
.header .nav ul li a{
  font-size: 18px;
}
}
</style>