<template>
  <div class="notice" v-if="item == 'denied' || item == null">
    <img src="@/assets/images/exclamation.png" alt="exclamation" />
    <p>
      {{ $t("policy.p") }}<router-link :to="{name: 'terms'}">{{ $t("policy.a") }}</router-link>
    </p>
    <button @click="policy">{{ $t("policy.btn") }}</button>
  </div>
</template>

<script>
export default {
  data() {
    const item = localStorage.getItem("policy");
    return {
      item: item,
    };
  },
  methods: {
    policy() {
      const div = document.querySelector(".notice");
      localStorage.setItem("policy", "accept");
      div.classList.toggle("close");
    },
  },
  mounted() {
    if (localStorage.getItem("policy") === "accept") {
      return;
    } else {
      localStorage.setItem("policy", "denied");
    }
  },
};
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.notice {
  position: fixed;
  bottom: 0;
  background: #fff;
  color: #212529;
  padding: 20px;
  margin: 20px;
  border-radius: 20px;
  box-shadow: 0 0 10px rgba(0,0,0,0.5);
  display: flex;
  align-items: center;
  z-index: 10000;
}
.notice img {
  margin-right: 20px;
}
.notice p{
  font-size: 14px;
}
.notice a{
  color: #ff9b13;
}
.notice button {
  min-width: 200px;
  height: 50px;
  border-radius: 10px;
  border: 0;
  margin-left: 20px;
  background: #ff9b13;
  color: #fff;
  cursor: pointer;
}
.close {
  display: none;
}
@media (max-width: 1100px) {
  .notice p {
    font-size: 13px;
  }
}
@media (max-width: 960px) {
  .notice {
    flex-direction: column;
  }
  .notice img {
    margin: 0;
  }
  .notice p{
    text-align: center;
  }
  .notice button {
    width: 200px;
    padding: 5px 10px;
    margin: 20px 0 0 0;
    border-radius: 5px;
  }
}
</style>