<template>
  <div class="terms">
    <Header />
    <Policy />
    <h2>{{$t("terms.h2")}}</h2>
    <p>{{$t("terms.p1")}}
      <br />
      <br />
      <br />
      <span>{{$t("terms.span")}}</span> 
      <br />
      <br />
      {{$t("terms.p2")}} <br/>
      {{$t("terms.p3")}}
    </p>
    <!-- <Contact />
    <Feedback /> -->
    <Footer />
    </div>
</template>

<script>
import Header from "@/components/Header";
/* import Contact from '@/components/Contact'
import Feedback from "@/components/Feedback"; */
import Footer from "@/components/Footer";
import Policy from "@/components/Policy";
export default {
  components: {
    Header,
    /* Contact,
    Feedback, */
    Footer,
    Policy,
  },
};
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.terms {
  width: 100%;
  min-height: 100vh;
}
.terms h2{
  padding: 30px 120px;
  background: #ff9b13;
  color: #fff;
}
.terms p{
  padding: 50px 120px;
  height: 100vh;
}
.terms span{
  font-weight: bold;
}
@media(max-width: 960px){
  .terms h2{
    padding: 30px 50px;
  }
  .terms p{
    padding: 50px;
  }
}
</style>