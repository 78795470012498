<template>
  <div class="footer">
      <div class="dev">
          <p>{{$t('footer.pFooter')}}<a href="https://www.linkedin.com/in/lucas-dos-santos-gomes-a8a973194" target="_blank">Lucas Gomes</a> - <router-link :to="{name: 'terms'}" id="terms">{{$t("terms.h2")}}</router-link></p>
          <p></p>
          <a href="#" id="home">{{$t("footer.aName")}}</a>
      </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.footer{
    position: relative;
    width: 100%;
    background: #344146;
    color: #fff;
    border-top: solid 1px rgb(0,0,0,0.2);
    box-shadow: 0 0 10px rgb(0,0,0,0.4);
    width: 100%;
}
.dev{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 30px 120px;
}
.dev a{
  color: rgb(0, 110, 255);
  font-weight: bold;
  text-decoration: none;
}
#terms{
    color: #fff;
    text-decoration: underline;
    font-weight: 400;
  }
#home{
  color: #fff;
  text-decoration: underline;
  font-weight: 400;
}
@media(max-width: 975px){
  .dev{
    padding: 20px 0;
    flex-direction: column-reverse;
    text-align: center;
  }
  .dev a{
    padding-bottom: 20px;
  }
}
</style>